<template>
    <div class="content-wrapper">
        <template v-if="isList">
        <div class="page-header">
        <div class="page-header-content header-elements-md-inline">
            <div class="page-title d-flex">
            <h4>
                <span class="font-weight-semibold">UGD Rekam Medis</span>
            </h4>
            </div>
        </div>
        </div>
        <div class="content pt-0">
            <div class="">
                <div class="card">
                    <ul class="nav nav-justified nav-tabs nav-tabs-highlight mb-0">
                        <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Verifikasi Rekam Medis <span class="badge badge-info">{{getTotal}}</span></a></li>
                        <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" class="nav-link">Permintaan Perubahan Data Assesment<span class="badge bg-teal">{{getTotalVerif}}</span></a></li>
                        <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" :class="activeTab == 3 ? 'nav-link active' : 'nav-link'" class="nav-link">Permintaan Perubahan Data Triase<span class="badge bg-teal">{{getTotalTriase}}</span></a></li>
                    </ul>
                    <div class="tab-content">
                        <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="verRekamMedis">
                            <div class="bg-white card-header">
                                <div class="row align-items-center">
                                    <div class="col-md-5">
                                        <div class="form-row">
                                            <div class="col-md-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                                            
                                            <div class="col-md-8" v-if="isshowTable('aur_reg_date')">
                                                <div class="form-group mb-0 d-flex">
                                                    <date-range-picker
                                                        ref="picker"
                                                        :locale-data="datePickerConfig.locale"
                                                        :autoApply="datePickerConfig.autoApply"
                                                        v-model="dateRange"
                                                        :opens="'right'"
                                                        :ranges="datePickerConfig.ranges"
                                                        @update="updateValues"
                                                    >
                                                        <template v-slot:input="picker">
                                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                        </template>
                                                    </date-range-picker>
                                                    <div class="input-group-append calendar-group">
                                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto col-lg-7">
                                        <div class="form-row justify-content-end">
                                            <!--
                                            <div class="col-md-5">
                                                <div class="lbl_status_pasien bg_red">
                                                    <h3>
                                                        <i class="icon-users2"></i>
                                                        {{getQueue}} PASIEN ANTRI
                                                    </h3>

                                                </div>
                                            </div>
                                            -->
                                            <div class="col-md-5">
                                                <div class="lbl_status_pasien bg_green">
                                                    <h3>
                                                        <i class="icon-user-check"></i>
                                                        {{totalTindakan}} Verifikasi Rekam Medis
                                                    </h3>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-row">
                                            <div class="col-md-auto">
                                                <div class="form-group mb-0">
                                                    <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                                                </div>
                                            </div>
                                            <div class="col-md-auto">
                                                <div class="form-group mb-0">
                                                    <button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover ><i class="icon-table2"></i></button>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-4">
                                                <b-form-group class="mb-0">
                                                    <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.verif_status" :options="Config.mr.configStatusRMUGD" label="text" :reduce="v=>v.value"></v-select>
                                                </b-form-group>
                                            </div>
                                            <!--
                                            <div class="col-md-4" v-if="isshowTable('mpo_name')">
                                                <b-form-group class="mb-0">
                                                    <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                </b-form-group>
                                            </div>
                                            -->
                                            <!--
                                            <div class="col-md-4">
                                                <b-form-group>
                                                    <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.process_status" :options="Config.mr.configStatus" label="text" :reduce="v=>v.value"></v-select>
                                                </b-form-group>
                                            </div>
                                            -->
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                            <input class="form-control"
                                                v-model="filter.search"
                                                @input="doSearch"
                                                placeholder="Ketik Nama/No. RM Pasien"
                                            />
                                            <div class="form-control-feedback">
                                                <i class="icon-search4 text-indigo"></i>
                                            </div>
                                                
                                            <b-button
                                                class="ml-1 d-inline-flex align-items-center"
                                                variant="outline-success"
                                                id="resetBtn"
                                                @click="doResetData()"
                                            >Reset</b-button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive sticky-table">
                                <table class="table table-bordered table-striped table-hover table-sm patient-table dashboard-table">
                                    <thead>
                                        <tr>
                                        
                                            <th>No.</th>
                                            <th v-if="isshowTable('aur_reg_code')">No. Kedatangan</th>
                                            <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                                            <th v-if="isshowTable('aus_name')">Status</th>
                                            <th v-if="isshowTable('aur_reg_date')">Tanggal Daftar</th>
                                            <th v-if="isshowTable('ap_code')">No. RM</th>
                                            <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                                            <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                                            <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                                            <th v-if="isshowTable('ap_usia')">Usia</th>
                                            <th v-if="isshowTable('ap_gender')">Jenis Kelamin</th>
                                            <th v-if="isshowTable('aur_is_lab')">Penunjang</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!loadingTable" >
                                        <template v-for="(v,k) in dataReg" >
                                            <tr :key="k" :class="v.aur_process_status_ppa_lab == 'QUEUE' ? 'table-info' : v.aur_process_status_ppa_lab == 'VOID' ? 'table-danger' : ''">
                                                <td>{{k+1}}</td>
                                                
                                                <td v-if="isshowTable('aur_reg_code')">
                                                {{v.aur_reg_code||"-"}}
                                                <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                                <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                                <td v-if="isshowTable('ap_fullname')">
                                                
                                                <a v-if="v.aur_status >= 4" href="javascript:;" @click="periksaPasien(v)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right data-popup="tooltip" title="Verifikasi Rekam Medis Pasien" > {{uppercaseWord(v.ap_fullname)||"-"}}</a>
                                                <span v-else>
                                                {{uppercaseWord(v.ap_fullname)||"-"}}
                                                </span>
                                                
                                                <br/>
                                                <small class="text-danger" style="font-size: 10px;" v-if="v.ap_fullname && isSameName(v.ap_fullname)">Nama Pasien Sama</small>

                                                <p>{{v.ap_nik||"-"}}</p>
                                                </td>  

                                                <td v-if="isshowTable('aus_name')">
                                                    <div class="badge badge-dark mb-1" v-if="v.aur_is_void == 'Y'">
                                                        VOID
                                                    </div>
                                                    <template v-else>
                                                        <!-- 2 == dalam pengkajian --> 
                                                        <template v-if="v.aur_status == 2">
                                                            <div class="badge bg-warning mb-1" v-if="v.aur_is_primary_assesment_done != 'Y'">
                                                                Kajian Primer
                                                            </div>
                                                            
                                                            <div class="badge bg-warning mb-1" v-if="v.aur_is_secondary_assesment_done != 'Y'">
                                                                Kajian Sekunder
                                                            </div>

                                                            <div class="badge bg-info mb-1" v-if="v.aur_is_lab == 'Y' && v.aur_is_lab_done != 'Y'">
                                                                Laboratorium
                                                            </div>
                                                            <div class="badge bg-info mb-1" v-if="(v.aur_is_radiologi == 'Y' || v.aur_is_intra_penunjang == 'Y') && v.aur_is_radiologi_done != 'Y'">
                                                                Radiologi
                                                            </div>
                                                        </template>
                                                        <span v-else :class="`badge ${v.aus_color}`">{{uppercaseWord(v.aus_name)||'-'}}</span>
                                                    </template>
                                                    

                                                </td>

                                                <td v-if="isshowTable('aur_reg_date')">
                                                {{v.aur_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>  
                                                

                                                <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                                <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                                <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
                                                <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                                <td v-if="isshowTable('ap_usia')">
                                                {{v.ap_usia_with_ket||"-"}}
                                                <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                                </td>
                                                <td v-if="isshowTable('ap_gender')">{{getGender(v.ap_gender)||"-"}}</td>
                                                <td v-if="isshowTable('aur_is_lab')">
                                                    <div v-if="v.aur_is_radiologi == 'Y'" class="d-flex align-items-center">
                                                        <i class="icon-checkmark-circle text-success"></i>
                                                        <span class="ml-1">Radio</span>
                                                    </div>
                                                    <div v-if="v.aur_is_lab == 'Y'" class="d-flex align-items-center">
                                                        <i class="icon-checkmark-circle text-success"></i>
                                                        <span class="ml-1">Lab</span>
                                                    </div>
                                                    <span v-if="v.aur_is_lab !== 'Y' && v.aur_is_radiologi !== 'Y'"> - </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tbody v-if="loadingTable">
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="loadingInvinite"> 
                                                <tr>
                                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                                </tr>
                                    </tbody>
                                    <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                                        <tr>
                                            <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                                Data Tidak Ditemukan
                                            </th>
                                        </tr>                
                                    </tbody>                
                                </table>
                                <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                                    <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                                    <i class="icon-chevron-left"></i>
                                    </a>
                                    <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                                    <i class="icon-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div :class="(activeTab == 2 || activeTab == 3) ? 'tab-pane fade show active' : 'tab-pane fade'" id="reqChangeData">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="form-group mb-0 d-flex">
                                                    <date-range-picker
                                                        ref="picker"
                                                        :locale-data="datePickerConfig.locale"
                                                        :autoApply="datePickerConfig.autoApply"
                                                        v-model="dateRange"
                                                        :opens="'right'"
                                                        :ranges="datePickerConfig.ranges"
                                                        @update="updateValues"
                                                    >
                                                        <template v-slot:input="picker">
                                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                        </template>
                                                    </date-range-picker>
                                                    <div class="input-group-append calendar-group">
                                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                            <input class="form-control"
                                                v-model="filter.search"
                                                @input="doSearch"
                                                placeholder="Ketik Nama/No. RM Pasien"
                                            />
                                            <div class="form-control-feedback">
                                                <i class="icon-search4 text-indigo"></i>
                                            </div>
                                                
                                            <b-button
                                                class="ml-1 d-inline-flex align-items-center"
                                                variant="outline-success"
                                                id="resetBtn"
                                                @click="doResetData()"
                                            >Reset</b-button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                                    <thead>
                                        <tr>
                                            <th>Tgl</th>
                                            <th>Pembuat Permintaan</th>
                                            <th>Nama Pasien</th>
                                            <th>No. RM</th>
                                            <th>No. Reg</th>
                                            <th>Status</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!loadingTable" >
                                        <template v-for="(v,k) in dataReg" >
                                            <tr :key="k" >
                                                <td>{{v.aur_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>
                                                <td>
                                                    <div class="font-weight-semibold">{{uppercaseWord(v.bu_full_name)||"-"}}</div>
                                                    <small class="text-muted">{{uppercaseWord(v.mpo_name)||"-"}}</small>
                                                </td>
                                                <td>
                                                {{uppercaseWord(v.ap_fullname)||"-"}}
                                                </td>
                                                <td>{{v.ap_code||"-"}}</td>
                                                <td>{{v.aur_reg_code||"-"}} <br>
                                                    <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                                    <span v-else class="text-danger">PASIEN LAMA</span>
                                                </td>
                                                <td>
                                                    <div class="badge badge-dark mb-1" v-if="v.aur_is_void == 'Y'">
                                                        VOID
                                                    </div>
                                                    <template v-else>
                                                        <template v-if="v.aur_status == 2">
                                                            <div class="badge bg-warning mb-1" v-if="v.aur_is_primary_assesment_done != 'Y'">
                                                                Kajian Primer
                                                            </div>
                                                            
                                                            <div class="badge bg-warning mb-1" v-if="v.aur_is_secondary_assesment_done != 'Y'">
                                                                Kajian Sekunder
                                                            </div>

                                                            <div class="badge bg-info mb-1" v-if="v.aur_is_lab == 'Y' && v.aur_is_lab_done != 'Y'">
                                                                Laboratorium
                                                            </div>
                                                            <div class="badge bg-info mb-1" v-if="(v.aur_is_radiologi == 'Y' || v.aur_is_intra_penunjang == 'Y') && v.aur_is_radiologi_done != 'Y'">
                                                                Radiologi
                                                            </div>
                                                        </template>
                                                        <span v-else :class="`badge ${v.aus_color}`">{{uppercaseWord(v.aus_name)||'-'}}</span>
                                                    </template>
                                                </td>
                                                <td>
                                                    <a href="javascript:;" @click="requestApprove(v)" data-toggle="modal" data-target="#confirmEditChanges" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" title="" data-original-title="Konfirmasi Perubahan"><i class="icon-check2"></i></a>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tbody v-if="loadingTable">
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="loadingInvinite">
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!(dataReg||[]).length && !loadingTable">   
                                        <tr>
                                            <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                                Data Tidak Ditemukan
                                            </th>
                                        </tr>                
                                    </tbody>                
                                </table>
                                
                                <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                                    <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                                    <i class="icon-chevron-left"></i>
                                    </a>
                                    <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                                    <i class="icon-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <b-modal 
                    v-model="openFilter"
                    :title="'Filter Kolom'"
                    size="sm"
                    ok-title="Terapkan"
                    @ok="terapkan()"
                >
                    <div class="row">
                        <div class="col-md-12">
                        <div class="wrap_line">
                            <div class="form-check p-0">
                                <label class="form-check-label d-flex">
                                <b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled" name="radio-inline-left" />
                                Tampilkan Semua
                                </label>
                            </div>
                            <hr class="my-2">
                            <div class="pl-2">
                                <b-form-group>
                                    <b-form-checkbox-group
                                        id="checkbox-block"
                                        v-model="selectedFilter"
                                        :options="optionFilter"
                                        name="filter"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                            </div>
                        </div>
                    </div>
                </b-modal>


                
                <b-modal 
                    v-model="detailData"
                    :title="'Data Tindakan Lab'"
                    size="lg"
                    body-class="p-2"
                    hide-footer
                >
                    <div class="modal-body">
                        <div class="row g-3">
                            <template v-for="(v,k) in rowDetail.aupdl_data">
                                <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                    <div class="card shadow-0 border">
                                        <div class="card-header py-2 bg-light">
                                        <h6 class="card-title text-uppercase font-weight-semibold">
                                            {{v.head||"-"}}</h6>
                                        </div>
                                        <div class="card-body py-2">
                                        <template v-for="v1,k1 in (v.dubData||[])">
                                            <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)"
                                            :key="k1+'labdatas'">
                                            <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                            <template v-for="v2,k2 in (v1.data||[])">
                                                <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                                <i
                                                    class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                <span class="align-middle">{{v2.text||"-"}}</span>
                                                <span v-if="v2.notes">, {{v2.notes}}</span>
                                                </div>
                                            </template>
                                            </div>
                                        </template>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="col-md-4 col-lg-3" v-if="rowDetail.aupdl_hasil_lainnya">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">Item
                                    Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                    <div>
                                        <i
                                        class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle">{{rowDetail.aupdl_hasil_lainnya||"-"}}</span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <span v-if="!selectedLabInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                        </div>
                    </div>
                </b-modal>

                        
                <b-modal v-model="openVerif"
                    :title="'Konfirmasi Pengajuan Perubahan'"
                    size="sm"
                    hide-footer
                >
                    <div class="modal-body p-0">
                        <div class="text-center">
                        <div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
                            <i class="icon-warning2"></i>
                        </div>
                        <h6 class="mb-3">Konfirmasi pengajuan perubahan pengkajian?</h6>
                        </div>
                    </div>
                    <div class="modal-footer p-0 mt-3">
                        <button type="button" @click="konfirmasiApprove('N')"  class="btn btn-outline-danger" data-dismiss="modal">Tidak Setuju</button>
                        <button type="button" @click="konfirmasiApprove('Y')" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>
                        Ya, Setuju</button>
                    </div>
                </b-modal>
            </div>
        </div>
        </template>
        <template v-else>
            <Form :row.sync="row" v-bind="passToSub"/>
        </template>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'
import Form from './Form.vue'

// import Datepicker from 'vuejs-datepicker'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getAction: 0,
            onScrollPoint: false,
            tableScrollPoint: null,
            canConfirm: false,
            
            optionFilter: [
                { text: 'No. Kedatangan', value: 'aur_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'aus_name' },
                { text: 'Tanggal Daftar', value: 'aur_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Cara Bayar', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'ap_gender' },
                { text: 'Penunjang', value: 'aur_is_lab' },
            ],
            selectedFilter: [
                'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','aur_is_lab','ap_code'
            ],
            acceptedFilter: [
                'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','aur_is_lab','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            openVerifikasiLab: false,
            isPanggil: false,
            detailData: false,
            rowDetail: {},
            changeJadwal: false,
            totalTindakan: 0,
            url: 'rekamMedis',
            activeTab: 1,
            openVerif: false,
            getTotal: 0,
            getTotalVerif: 0,
            getTotalTriase: 0
        }
    },
    computed: { 
        passToSub(){
            return _.assign(this.passToSubComp||{})
        },
        disabledDates() {
            return {
                to: new Date(moment().format('YYYY-MM-DD'))
            }
        },   
        countSkrining(){
            let total = 0
            for(let i = 0; i < (this.dataGizi.asg_data||[]).length; i++){
                if(this.dataGizi.asg_data[i]['value']){
                    total += 1
                }
            }
            return total
        },
        selectedLabInput() {
            let data = []
            for (let ik = 0; ik < (this.rowDetail.aupdl_data || []).length; ik++) {
                for (let jk = 0; jk < (this.rowDetail.aupdl_data[ik]['dubData'] || []).length; jk++) {
                    for (let kk = 0; kk < (this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
                    if (this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                        data.push(this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                    }
                    }
                }
            }
            if (this.rowDetail.aupdl_hasil_lainnya) {
              data.push(this.rowDetail.aupdl_hasil_lainnya)
            }
            return data.join(", ")
        },
    },
  	components:{DateRangePicker,Form
    //   Datepicker
    },
    methods: {
        requestApprove(v){
            this.rowEdit = v
            this.openVerif = true
        },
        konfirmasiApprove(val){
            this.loadingOverlay=true
            let typeUrl = this.activeTab == 2 ?'approval':'approval-triase'
            let idData = this.activeTab == 2 ? this.rowEdit.aurm_id : this.rowEdit.aut_id
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:typeUrl, id: idData, aurm_is_approve: val, aut_is_approve: val}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.openVerif = false
                this.$swal({
                    title: val == 'Y' ? `Permintaan Berhasil Disetujui` : `Permintaan Berhasil DiTolak`,
                    icon: 'success',
                })

                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet()
                }

            }).catch(err=>{ 
                this.loadingOverlay = false 
                this.openVerif = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        },
        isSameName(name){
            let index = this.dataReg.findIndex(x=>x.ap_fullname == name)
            let count = 0
            for (let i = 0; i < this.dataReg.length; i++) {
                if (this.dataReg[i].ap_fullname === name) {
                    count++
                }
            }
            if(count > 1){
                return "text-warning"
            }else{
                return ""
            }
        },
        doRefreshData(){
            this.apiGet(true)
            window.scrollTo(0,0)
        },
        otherConditional(v){
            return moment().format('YYYY-MM-DD') <= this.to24Hours(v.aurm_created_date) && v.aur_is_created_upla !== 'Y'
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_rekam_medis_ugd_${this.user.id}`,JSON.stringify(this.acceptedFilter))
            this.apiGet()
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },500),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(isLoad = false){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isLoad){
                this.loadingTable = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage, 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uLab : null,
                        urlData: this.url
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
                this.totalData = resp.dataReg.total

                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }
                }else{
                    this.dataReg = resp.dataReg.data
                    this.totalTindakan = resp.totalTindakan
                }   

                this.getTotal = resp.getTotal
                this.getTotalVerif = resp.getTotalVerif
                this.getTotalTriase = resp.getTotalTriase

            })
        },
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'UGDRekamMedis'){                        
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
            if (this.onScrollPoint && scrollEl.scrollTop == 0) {
                this.onScrollPoint = false
                window.removeEventListener('wheel', this.tableScrollFunction, false)
            }
        },
        requestEdit(v){
            this.rowEdit = v
            this.openPersetujuan = true
        },

        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','ap_gender','aur_is_lab','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        
        redirect(v){
            this.$router.push({ name: 'UGDFormLab', params: { pageSlug: v.ap_id, rmNo: v.aurm_id}, query: {regId: v.aur_id} }).catch(()=>{})
        
        },

        tableScrollFunction(e) {
           const responsiveTable = document.querySelector('.table-responsive')
           if(responsiveTable){
                let scrollDelta = e.deltaY
                let resScrollPos = responsiveTable.scrollTop + scrollDelta
                responsiveTable.scroll({
                    left: responsiveTable.scrollLeft,
                    top: resScrollPos,
                    behavior: 'smooth'
                })
            }
        },
        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    Array.from(responsiveTableScrollActions).forEach(action => {
                        action.addEventListener('click', e => {
                            const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                             
                            responsiveTable.scroll({
                                left: scrollAmount,
                                behavior: 'smooth'
                            })
                        })
                    })
                }
            }
        },
        
        
        windowTableScroller(e){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                    const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                    if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                        this.onScrollPoint = true
                        document.documentElement.scroll(0, this.tableScrollPoint)
                        window.addEventListener('wheel', this.tableScrollFunction, false)
                    }
                }
            }
        },
        checkNullAction(cmp){
            return $(`#${cmp}`).is(':empty')
        },

        periksaPasien(v){

            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'duplicate-icd', id: v.aur_id}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false
                this.$router.push({ name: 'UGDRekamMedis', params: { pageSlug: v.aur_id } }).catch(()=>{})
            })


            // if(this.user.levelId == 1){
            //     this.$router.push({ name: 'UGDAssesment', params: { pageSlug: v.ap_id }, query: {regId: v.aur_id, byPassLevel : this.uLab} }).catch(()=>{})
            // }else{
            //     this.$router.push({ name: 'UGDAssesment', params: { pageSlug: v.ap_id }, query: {regId: v.aur_id} }).catch(()=>{})
            // }
        },
        
        changeTab(e){
            this.activeTab = e
            if(e == 1){
                this.url = 'rekamMedis'
            }else if(e == 2){
                this.url = 'verifyRekamMedis'
            }else{
                this.url = 'verifyTriase'
            }
            this.apiGet()
        },
    },
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_lab_ugd_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1

        if(this.$route.query.page != 1 && this.isList){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet()
        }
        
        if(this.filter.poli){
            this.filter.poli = +this.filter.poli
        }
        
        setTimeout(()=>{
            this.setSlide()
        },1000)
        const stickyTable = document.querySelector('.sticky-table')
        if(stickyTable){
            stickyTable.addEventListener('scroll', this.handleScroll)
            window.addEventListener('scroll', this.windowTableScroller)
            this.onScrollPoint = false
        }
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('scroll', this.windowTableScroller)
    },
    watch:{
        $route(){
           this.apiGet()
           setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        },
        'onScrollPoint'(v){
            document.body.style.overflow = v ? 'hidden' : 'auto'
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
  .dashboard-table th, .dashboard-table td {
    white-space: normal !important;  
  }
</style>